import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  Fragment,
} from "react";
// import PurchaseOrderContext from "../PurchaseOrderContext";
import PurchaseOrderTable from "../components/PurchaseOrderTable";
import { withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import moment from "moment";
import { withClaims } from "../../../shared/components/Session";
import { compose } from "recompose";

function PurchaseOrderTableContainer(props) {
  const orders = props?.orders ?? [];
  const [tableRows, setTableRows] = useState([]);
  const [unitRate, setUnitRate] = useState(0);
  const [loadVar, setLoadVar] = useState(true);
  const userRole = props.claimsData && props.claimsData.role;
  //const userRole = "purchase"; //["admin","super_admin",purchase]

  const statusView = (status) => {
    if (status == "open") {
      return (
        <div
          className={
            userRole == "purchase"
              ? "status-change red"
              : "status-change yellow"
          }
        >
          <b>Open</b>
        </div>
      );
    } else if (status == "rejected") {
      return (
        <div className="status-change grey">
          <b>Rejected</b>
        </div>
      );
    } else if (status == "a_approval") {
      return (
        <div
          className={
            userRole == "admin" ? "status-change red" : "status-change yellow"
          }
        >
          <b>Sent for Admin Approval</b>
        </div>
      );
    } else if (status == "a_approved") {
      return (
        <div
          className={
            userRole == "super_admin"
              ? "status-change red"
              : "status-change yellow"
          }
        >
          <b>Admin Approved</b>
        </div>
      );
    } else if (status == "sa_approved") {
      return (
        <div
          className={
            userRole == "purchase"
              ? "status-change red"
              : "status-change yellow"
          }
        >
          <b>Super Admin Approved</b>
        </div>
      );
    } else if (status == "vp") {
      return (
        <div
          className={
            userRole == "purchase"
              ? "status-change red"
              : "status-change yellow"
          }
        >
          <b>Vendor Processing</b>
        </div>
      );
    } else if (status == "delivered") {
      return (
        <div className="status-change green">
          <b>Received</b>
        </div>
      );
    }
  };

  useEffect(() => {
    const tableRows = orders?.map((order, index) => [
      order.customId,
      order.supplierInfo ? order.supplierInfo.name : "N/A",
      order.purchasedFor && order.purchasedFor.name,
      order.projectInfo && order.projectInfo.name,
      moment(order.createdAt).format("DD/MM/YYYY   hh:mm a"),
      statusView(order.status),
    ]);

    setTableRows(tableRows);
    setLoadVar(false);
  }, [orders]);
  const columns = [
    "PO ID",
    "SUPPLIER NAME",
    "EMPLOYEE",
    "PROJECT",
    "CREATED DATE",
    "STATUS",
  ];
  const options = {
    rowsPerPageOptions: [6, 5, 4],
    rowsPerPage: 5,
    onRowClick: (data, index) => {
      props.history.push(`/tool/order-info/${orders[index.dataIndex]._id}`);
    },

    // disableToolbarSelect: false,
    selectableRows: false,
  };
  return (
    <Fragment>
      {!loadVar ? (
        <PurchaseOrderTable
          orders={tableRows}
          columns={columns}
          options={options}
        />
      ) : (
        <div className="loader-style">
          <Loader
            type="ThreeDots"
            color="#7764e4"
            height={100}
            width={100}
            // timeout={3000} //3 secs
          />
        </div>
      )}
    </Fragment>
  );
}

export default compose(withRouter, withClaims)(PurchaseOrderTableContainer);
